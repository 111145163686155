export const Nutrition = {
  user_id: 0,
  date: new Date().toJSON().slice(0, 10),
  foodNameB: 'foodNameB',
  saturatedB: 0.0,
  transB: 0.0,
  polyunsaturatedB: 0.0,
  monosaturatedB: 0.0,
  cholesterolB: 0,
  sodiumB: 0,
  carbsB: 0,
  fiberB: 0,
  sugarsB: 0.0,
  proteinB: 0.0,
  vitaminAB: 0,
  vitaminCB: 0,
  vitaminDB: 0,
  calciumB: 0,
  ironB: 0.0,
  potassiumB: 0,
  foodNameL: 'foodNameL',
  saturatedL: 0.0,
  transL: 0.0,
  polyunsaturatedL: 0.0,
  monosaturatedL: 0.0,
  cholesterolL: 0,
  sodiumL: 0,
  carbsL: 0,
  fiberL: 0,
  sugarsL: 0.0,
  proteinL: 0.0,
  vitaminAL: 0,
  vitaminCL: 0,
  vitaminDL: 0,
  calciumL: 0,
  ironL: 0.0,
  potassiumL: 0,
  foodNameD: 'foodNameD',
  saturatedD: 0.0,
  transD: 0.0,
  polyunsaturatedD: 0.0,
  monosaturatedD: 0.0,
  cholesterolD: 0,
  sodiumD: 0,
  carbsD: 0,
  fiberD: 0,
  sugarsD: 0.0,
  proteinD: 0.0,
  vitaminAD: 0,
  vitaminCD: 0,
  vitaminDD: 0,
  calciumD: 0,
  ironD: 0.0,
  potassiumD: 0,
  foodNameBB: 'foodNameBB',
  saturatedBB: 0.0,
  transBB: 0.0,
  polyunsaturatedBB: 0.0,
  monosaturatedBB: 0.0,
  cholesterolBB: 0,
  sodiumBB: 0,
  carbsBB: 0,
  fiberBB: 0,
  sugarsBB: 0.0,
  proteinBB: 0.0,
  vitaminABB: 0,
  vitaminCBB: 0,
  vitaminDBB: 0,
  calciumBB: 0,
  ironBB: 0.0,
  potassiumBB: 0,
  foodNameBed: 'foodNameBed',
  saturatedBed: 0.0,
  transBed: 0.0,
  polyunsaturatedBed: 0.0,
  monosaturatedBed: 0.0,
  cholesterolBed: 0,
  sodiumBed: 0,
  carbsBed: 0,
  fiberBed: 0,
  sugarsBed: 0.0,
  proteinBed: 0.0,
  vitaminABed: 0,
  vitaminCBed: 0,
  vitaminDBed: 0,
  calciumBed: 0,
  ironBed: 0.0,
  potassiumBed: 0,
};
