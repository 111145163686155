export const Medication = {
  user_id: 0,
  name: 'Name',
  dose: 0,
  quantity: 0,
  prescriber: 'Name',
  am: 0,
  noon: 0,
  evening: 0,
  bed: 0,
};
