export const timesPD = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
];

export const typInsulin = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
];

export const slidingScale1 = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '100',
    label: '100',
  },
  {
    value: '200',
    label: '200',
  },
  {
    value: '300',
    label: '300',
  },
];

export const slidingScale2a = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '101',
    label: '101',
  },
  {
    value: '201',
    label: '201',
  },
  {
    value: '301',
    label: '301',
  },
];

export const slidingScale2b = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '150',
    label: '150',
  },
  {
    value: '250',
    label: '250',
  },
  {
    value: '350',
    label: '350',
  },
];

export const slidingScale3a = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '151',
    label: '151',
  },
  {
    value: '251',
    label: '251',
  },
  {
    value: '351',
    label: '351',
  },
];

export const slidingScale3b = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '200',
    label: '200',
  },
  {
    value: '300',
    label: '300',
  },
  {
    value: '400',
    label: '400',
  },
];

export const slidingScale4a = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '201',
    label: '201',
  },
  {
    value: '301',
    label: '301',
  },
  {
    value: '401',
    label: '401',
  },
];

export const slidingScale4b = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '250',
    label: '250',
  },
  {
    value: '350',
    label: '350',
  },
  {
    value: '450',
    label: '450',
  },
];

export const slidingScale5 = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '251',
    label: '251',
  },
  {
    value: '351',
    label: '351',
  },
  {
    value: '451',
    label: '451',
  },
];

export const carbRatio = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  },
  {
    value: '7',
    label: '7',
  },
  {
    value: '8',
    label: '8',
  },
  {
    value: '9',
    label: '9',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '11',
    label: '11',
  },
  {
    value: '12',
    label: '12',
  },
  {
    value: '13',
    label: '13',
  },
  {
    value: '14',
    label: '14',
  },
  {
    value: '15',
    label: '15',
  },
  {
    value: '16',
    label: '16',
  },
  {
    value: '17',
    label: '17',
  },
  {
    value: '18',
    label: '18',
  },
  {
    value: '19',
    label: '19',
  },
  {
    value: '20',
    label: '20',
  },
  {
    value: '21',
    label: '21',
  },
  {
    value: '22',
    label: '22',
  },
  {
    value: '23',
    label: '23',
  },
  {
    value: '24',
    label: '24',
  },
  {
    value: '25',
    label: '25',
  },
  {
    value: '26',
    label: '26',
  },
  {
    value: '27',
    label: '27',
  },
  {
    value: '28',
    label: '28',
  },
  {
    value: '29',
    label: '29',
  },
  {
    value: '30',
    label: '30',
  },
  {
    value: '31',
    label: '31',
  },
  {
    value: '32',
    label: '32',
  },
  {
    value: '33',
    label: '33',
  },
  {
    value: '34',
    label: '34',
  },
  {
    value: '35',
    label: '35',
  },
  {
    value: '36',
    label: '36',
  },
  {
    value: '37',
    label: '37',
  },
  {
    value: '38',
    label: '38',
  },
  {
    value: '39',
    label: '39',
  },
  {
    value: '40',
    label: '40',
  },
  {
    value: '41',
    label: '41',
  },
  {
    value: '42',
    label: '42',
  },
  {
    value: '43',
    label: '43',
  },
  {
    value: '44',
    label: '44',
  },
  {
    value: '45',
    label: '45',
  },
  {
    value: '46',
    label: '46',
  },
  {
    value: '47',
    label: '47',
  },
  {
    value: '48',
    label: '48',
  },
  {
    value: '49',
    label: '49',
  },
  {
    value: '50',
    label: '50',
  },
];
